import React from 'react'
import Axios from 'axios';
import { Link } from 'react-router-dom';
import NewNFT from './NewNFT.jsx'


// MANAGING AIRDROP FORMS

class NFTs extends React.Component {

  constructor(props){
      super(props);  
      this.state = {
        passes: [],
        music: [],
        filters: []
      }

      this.getCollections = this.getCollections.bind(this)
      this.filter = this.filter.bind(this)
      this.getImg = this.getImg.bind(this)
      this.moveUp = this.moveUp.bind(this)
      this.moveDown = this.moveDown.bind(this)      
      this.hide = this.hide.bind(this)      
      this.show = this.show.bind(this)      
  }

  componentWillMount(){
    var that = this;
    var address = this.props.address

    if(address && address !=that.state.address){
      that.setState({
        address: address
      })
      that.getCollections(address)
    }
 
  }

  getImg = async(contract, callback) => {
    // const response = await fetch('https://api.rarible.org/v0.1/collections/ETHEREUM:'+contract)
    // const response = await fetch('https://api.opensea.io/api/v1/asset_contract/'+contract)
    
    if(contract.indexOf('TEZOS')>=0){
        const response = await fetch('https://api.rarible.org/v0.1/items/'+contract)
        const data = await response.json()
        console.log('tezos res getImg', data)
        callback(data['meta']['content'][0]['url'])
    }
    else{
      const response = await fetch('https://api.opensea.io/api/v1/asset/'+contract+'/1')
      const data = await response.json()
      if(!data['image_url']){
        const response = await fetch('https://api.opensea.io/api/v1/asset_contract/'+contract)
        const data2 = await response.json() 
        if(!data2){
          callback('')
        }
        else {
          callback(data2['image_url'])            
        }
      }
      else{
        callback(data['image_url'])
      }    
    }

  }

  getCollections(address){
    console.log('hit myCollections', address)
    if(!address){
      address = this.state.address
    }

    var that = this;
    var drops = []

    Axios.post('https://future-api-2.herokuapp.com/getCollections',{wallet:address})
      .then(function (response) {
        console.log('response_back myCollections', response.data)
        drops = response.data

        var newDrops = []

        drops.forEach(function(drop){
          if(!drop.visual && drop.contract){
            that.getImg(drop.contract, function(newVisual){
               drop['visual'] = newVisual
               newDrops.push(drop)
              if(drops.length == newDrops.length){
                  that.setState({
                    collections: newDrops,
                    collectionsB: newDrops
                  })
                }    
            })
          }
          else{
            newDrops.push(drop)
            if(drops.length == newDrops.length){
              that.setState({
                collections: newDrops,
                collectionsB: newDrops
              })
            }          
          }
        })
      })
      .catch(function (error) {
        console.log('This is a bad response',  error);    
        });   

  }

  filter(e){
    var that = this
    var target = e.target.id
    var newFilters = this.state.filters
    var index = newFilters.indexOf(target);
    if (index !== -1) {
      newFilters.splice(index, 1);
    }
    else{
      newFilters.push(target)
    }
    if(newFilters.length == 0){
      that.setState({
        collectionsB:that.state.collections,
        filters: newFilters
      })    
    }
    else{
      newFilters.forEach(function(category){
        var newCollections = that.state.collections.filter(function(collection){
          if(collection['type'].toLowerCase() == category.toLowerCase()){
            return true
          }
          else{
            return false
          }
        })
        that.setState({
          collectionsB:newCollections,
          filters: newFilters
        })
      })
    }
  }

  moveUp(e){
    var that = this;    
    console.log('movin up', e.target.id)
    console.log('collectionsB', this.state.collectionsB)

    Axios.post('https://future-api-2.herokuapp.com/moveCollection',{
      wallet:this.state.address,
      record:e.target.id,
      direction:'up'
    })
      .then(function (response) {
        console.log('response_back moveUp', response.data)
        that.getCollections()
      })
      .catch(function (error) {
        console.log('This is a bad response',  error);    
        });
  }

  moveDown(e){
    var that = this;
    console.log('movin down', e.target.id)
    console.log('collectionsB', this.state.collectionsB)

    Axios.post('https://future-api-2.herokuapp.com/moveCollection',{
      wallet:this.state.address,      
      record:e.target.id,
      direction:'down'
    })
      .then(function (response) {
        console.log('response_back moveUp', response.data)
        that.getCollections()        
      })
      .catch(function (error) {
        console.log('This is a bad response',  error);    
        });
  }

  hide(e){
    var that = this;    
    console.log('hide', e.target.id)
    console.log('collectionsB', this.state.collectionsB)

    Axios.post('https://future-api-2.herokuapp.com/toggleCollection',{
      wallet:this.state.address,
      record_id:e.target.id,
      status:'hide'
    })
      .then(function (response) {
        console.log('response_back hide', response.data)
        that.getCollections()
      })
      .catch(function (error) {
        console.log('This is a bad response',  error);    
        });
  }

  show(e){
    var that = this;    
    console.log('show', e.target.id)
    console.log('collectionsB', this.state.collectionsB)

    Axios.post('https://future-api-2.herokuapp.com/toggleCollection',{
      wallet:this.state.address,
      record_id:e.target.id,
      status:'show'
    })
      .then(function (response) {
        console.log('response_back show', response.data)
        that.getCollections()
      })
      .catch(function (error) {
        console.log('This is a bad response',  error);    
        });
  }


  render(){

    var that = this

    // if airdrop forms exist, render forms (AKA "drops")


     var collections = <></>      


    if(this.state.collectionsB && this.state.collectionsB.length > 0){

        var newCollections = that.state.collectionsB

        newCollections.sort((a,b) => a.rank - b.rank)

        var number = 0

        collections = newCollections.map(function(item){
            number = number+1
            var color = "blackBg"

            if(item.type == "Music"){
              var color = "purpleBg"
            }
            if(item.type=="Pass" || item.type=="Vibe Pass"){
              var color = "redBg"
            }

            var classes = "statusCode " + color

            var content = <div className={item.status == "hide" ? "card nft inactive":"card nft"}>
              <div className="cardImg">
                <img src={item.visual} />
              </div>
              <div className="cardInfo sec">
                <h4>{item.title ? item.title : "Vibe Pass"}</h4>
                <p>{item.supply} Editions</p>
              </div>
              <div className="nftToggles">
              {number == 1 ? <span className="material-symbols-outlined inactive">arrow_drop_up</span> : 
              <span className="material-symbols-outlined" id={item.record_id} onClick={that.moveUp}>arrow_drop_up</span>
              }
              {item.status == "hide" ? <span className="material-symbols-outlined inactive" id={item.record_id} onClick={that.show}>
              toggle_off
              </span> : 
              <span className="material-symbols-outlined" id={item.record_id} onClick={that.hide}>
              toggle_on
              </span>
              }
              {number == newCollections.length ? <span className="material-symbols-outlined inactive">arrow_drop_down</span> : 
              <span className="material-symbols-outlined" id={item.record_id} onClick={that.moveDown}>arrow_drop_down</span>
              }
              </div>
              </div>
              return content
            })
        }

    var filters = <div className="filters">
          <span className="material-symbols-outlined">filter_list</span>          
          <h4 className={this.state.filters.includes('music') ? "statusCode purpleBg active" : "statusCode purpleBg"} id="music" onClick={this.filter}>Music</h4>
          <h4 className={this.state.filters.includes('pass') ? "statusCode redBg active" : "statusCode redBg"} id="pass" onClick={this.filter}>Pass</h4>
          <h4 className={this.state.filters.includes('other') ? "statusCode blackBg active" : "statusCode blackBg"} id="other" onClick={this.filter}>Other</h4>
          </div>


    return (
      <div className="contentBottom form settings">
          <h3>NFT Collections</h3>
          {collections}
          <NewNFT address={that.props.address} refresh={that.getCollections} collections={that.state.collections}/>          
      </div>
    )
  }
}

export default NFTs
