import React from "react";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HashRouter, Route, Switch } from 'react-router-dom'


import Menu from "./Components/Menu";
import Brand from "./Components/Brand";
import Error from "./Components/Error";
import Home from "./Components/Home";

import Header from "./Components/Header"
import Wagmi from "./Components/wagmiConnector";

import Settings from "./Components/Settings";


function App() {
  return (
    <div className="App">
      <Header />
      <HashRouter>
        <Switch>
          <Route path="/error">
            <Error />
          </Route>

          <Route path="/">
            <Wagmi>
            <Settings />
            </Wagmi>
          </Route>


        </Switch>
      </HashRouter>
    </div>
  );
}

export default App;
