import React, { Fragment } from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import { useParams, useRouteMatch, Switch, Route } from "react-router-dom";

import Model from "./Model";
import SubMenu from "./SubMenu";
class Home extends React.Component {

  constructor(props){
      super(props);  
      this.state = {
      }
     
  }

  componentDidMount(){
    var that = this;
  }


  render(){

    console.log('hit render in home')

  var icon = "https://i.ibb.co/6gp7LCH/vol-icon.png"

  return (

     <div className="splash vol">
     <a href="https://twitter.com/voldao_">
     <img src={icon} />
     </a>
     </div>
    )
  }
}

export default Home
