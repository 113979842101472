import React from 'react'
import Axios from 'axios';

import { useEffect, useState } from 'react';
import { ethers } from 'ethers';

import { ConnectButton } from '@rainbow-me/rainbowkit';


class Header extends React.Component {

  constructor(props){
      super(props);  
      this.state = {
      }

  }


  render(){


    var voldao_icon_sm = 'https://i.ibb.co/wB75Y9H/voildao-icon-only.png'
    var voldao_icon_lg = 'https://i.ibb.co/1QTksmZ/voldao-icon-full.png"'

    var std = <div className="header">
          <a href="/">
          <img src={voldao_icon_sm}/>
          </a>
          <ConnectButton />
          </div>


    return std
  }
}

export default Header
