import React, { Fragment } from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import { useParams, useRouteMatch, Switch, Route } from "react-router-dom";
import Axios from 'axios';

import Model from "./Model";
import SubMenu from "./SubMenu";

class Main extends React.Component {

  constructor(props){
      super(props);  
      this.state = {
      }
      this.open = this.open.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.platformSelect = this.platformSelect.bind(this)
      this.createArtist = this.createArtist.bind(this)

  }


  handleChange(event){

    var name=event.target.name
    var newState = {}
    newState[name] = event.target.value.replace('@', '')
    console.log('state', newState)
    this.setState(newState)
  
  }

  componentDidMount(){
    var that = this;
  }

  open(){
    var newState={}

    if(this.state.open){
      newState['open']=false
      this.setState(newState)
    }
    else{
      newState['open']=true      
      this.setState(newState)
    }

  }

  platformSelect(e){
    console.log('hit platformSelect', e.target.value)

    this.setState({
      platform: e.target.value
    })

  }

  createArtist(e){
    var name=e.target.getAttribute('name')
    var name2 = "edit_"+name
    var that = this;

    var data = {account: this.props.address}

    if(that.state[name]){
        data[name] = that.state[name]
    }

    console.log('data', data)
  
    Axios.post('https://future-api-2.herokuapp.com/createArtist',data)
      .then(function (response) {
        console.log('response_back', response.data)
        that.open()
        that.props.lookupArtist()
      })
      .catch(function (error) {
        console.log('This is a bad response',  error);    
        });   

  }

  render(){
    var that = this;

    var options = this.props.platforms.map(function(item){
              if(!that.props.data[item.name]){
                return <option value={item.name}>{item.name2}</option>
              }
              })

    options = options.filter(function( element ) {
       return element !== undefined;
    });   

    var newLink = <></>

    if(options.length>0){

      var newLink = <div className="card link new center" onClick={this.open}>
              <span class="material-symbols-outlined">
              add
              </span>
            </div>

    }


    // var open =<h1>Hi</h1>

    var open = <div className="card link new">
            <div className="selectContainer">
            <select name="platforms" id="platforms" onChange={this.platformSelect}>
              <option value=''>Select a platform</option>
              {options}
            </select>
            </div>
             {this.state.platform && this.state.platform !== '' ? 
              <div className="linkSide">
              <input 
                  name={this.state.platform} 
                  onChange={this.handleChange} 
                  />
              <span name={this.state.platform} class="material-symbols-outlined" onClick={this.createArtist}>
                  check
                  </span>
                  <span name={this.state.platform} class="material-symbols-outlined" onClick={that.open}>
                  close
                  </span>
              </div> 
              : 
              <div className="linkSide">
              <span name={this.state.platform} class="material-symbols-outlined" onClick={that.open}>
                  close
                  </span>
              </div>
            }
            </div> 


    
    if(this.state.open){
      return open
    }
    else{
      return newLink
    }

  }

}

export default Main
