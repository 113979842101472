import React, { Fragment } from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import { useParams, useRouteMatch, Switch, Route } from "react-router-dom";
import Axios from 'axios';
import {Link} from "react-router-dom";

import Model from "./Model";
import SubMenu from "./SubMenu";
import NFTs from './NFTs.jsx'

class Home extends React.Component {

  constructor(props){
      super(props);  

      this.state = {
      }
      this.handleChange = this.handleChange.bind(this);
      this.createArtist = this.createArtist.bind(this);
  }

  componentWillMount(){
    var that = this;
    var address = this.props.address

    if(address && address !=that.state.address){
      that.setState({
        address: address
      })
    }
 
  }

  createArtist(e){
    var that = this;

    console.log('hit createArtist', this.data)

    var data = {
      artistName: this.state.artistName,
      username: this.state.username,
      code: this.state.code
    }

    data['account'] = this.state.address

    console.log('data', data)
  
    Axios.post('https://future-api-2.herokuapp.com/createArtist',data)
      .then(function (response) {
        console.log('response_back', response.data)
        if(response.data['invalid']==true){
          var newState={
            invalid:true
          }
          that.setState(newState)
        }
        else{
          var newState={
            created:true,
          }
          that.setState(newState)
          that.props.reload()   
        }
      })
      .catch(function (error) {
        console.log('This is a bad response',  error);    
        });   

  }
  
  handleChange(event){

    var name=event.target.name
    var newState = {}
    newState[name] = event.target.value.replace('@', '')
    console.log('state', newState)
    this.setState(newState)
  
  }


  render(){
    var that = this;


    var icon = "https://i.ibb.co/6gp7LCH/vol-icon.png"
    var basic = <></>
    var errors = <></>

    if(this.props.created){
      return <></>
    }


    basic = <div className="contentBottom start">
          <div className="wrapper card">   

          <div className="card mainCard">
            <div className="cardSide">
            <h4>Artist Name</h4>
            </div>
            <div className="cardSide wider">       
            <input 
                name="artistName" 
                placeholder='Jay-Z'
                onChange={this.handleChange} 
                />
            </div>
          </div>
      
          <div className="card mainCard">
            <div className="cardSide">
            <h4>Username</h4>
            </div>
            <div className="cardSide wider">
            <input 
                name="username" 
                placeholder='jayz'
                onChange={this.handleChange} 
                />
            </div>
          </div>
    
          <div className="card mainCard">
            <div className="cardSide">
            <h4>Access Code</h4>
            </div>
            <div className="cardSide wider">
            <input 
                name="code" 
                placeholder='ABC123'
                onChange={this.handleChange} 
                />
            </div>
          </div>
    

          {this.state.artistName && this.state.username && this.state.code ? 
            <button className="confirm" onClick={this.createArtist}>Continue</button>:
            <button className="confirm fade">Continue</button>}


          </div>

          {this.state.invalid ? <p className="error">Invalid Code</p> : <></>}


          </div>



    return (
      <div className="contentMain">
        <div className="contentTop">
        <h2>Get Started</h2>
         </div>
        {basic}
      </div>
      )
  }
}

export default Home
