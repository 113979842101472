import React, { Fragment } from "react";

import { useAccount, useConnect, useEnsName } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'

function Profile({ children }) {
  const { data: account } = useAccount()
  // const { data: ensName } = useEnsName({ address: account.address })
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })

  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child) && account) {
      return React.cloneElement(child, { address: account.address });
    }
    return child;
  });

  if (account) return childrenWithProps
  return <div className="splash_alt">
        <h4>Please click "Connect Wallet" to get started.</h4> 
     </div>
}

export default Profile;
