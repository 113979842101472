import React from 'react'
import Axios from 'axios';
import { Link } from 'react-router-dom';

import Header from './Header.jsx'

// MANAGING AIRDROP FORMS

class NewNFT extends React.Component {

  constructor(props){
      super(props);  
      this.state = {
        open: false,
        chain: 'eth',
        step: 1
      }

      this.getMetadata = this.getMetadata.bind(this);
      this.submit = this.submit.bind(this);
      this.toggleOpen = this.toggleOpen.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.chainSelect = this.chainSelect.bind(this);
      this.continue = this.continue.bind(this);

  }
  componentWillMount(){
    var that = this;
    var address = this.props.address

    if(address && address !=that.state.address){
      that.setState({
        address: address
      })
    }
 
  }

  handleChange(event) {
    console.log("handleChange value:", event.target.value);
    var field = event.target.name
    var newState = {}
    newState[field] = event.target.value
    this.setState(newState)
  }


  getMetadata = async(callback) => {
    console.log('hit getMetadata', this.state)

    var link = this.state.collectionLink
    var chain = this.state.chain

    if(chain=='eth'){

      console.log('mainnet')

      var spot = link.indexOf('collection/')
      var opensea_name = link.substring(spot+11,link.length)

      const response = await fetch('https://api.opensea.io/api/v1/collection/'+opensea_name)
      console.log('opensea response', response)    
      const data = await response.json()
      console.log('opensea response2', data)

      var metadata = {
        'contract': data['collection']['primary_asset_contracts'][0]['address'],
        'title': data['collection']['name'],
        'collectionLink': this.state.collectionLink,
        'type': 'other',
        'chain': this.state.chain,
        'supply': data['collection']['stats']['count']
      }

      if(data['collection']['image_url']){
        metadata['visual'] = data['collection']['image_url']
      }


      console.log('metadata', metadata)
      callback(metadata)

    }
    else if(chain == 'tezos'){
      console.log('tezos')

      var spot = link.indexOf('tezos/')
      var tezos_id = link.substring(spot+6,link.length)
      console.log('link', link)    

      const response = await fetch('https://api.rarible.org/v0.1/items/TEZOS:'+tezos_id)
      console.log('rarible response', response)    
      const data = await response.json()
      console.log('rarible response2', data)

      var metadata = {
        'contract': data['contract']+':'+data['tokenId'],
        'tokenId': data['tokenId'],
        'title': data['meta']['name'],
        'collectionLink': this.state.collectionLink,
        'type': 'other',
        'chain': this.state.chain,
        'supply': data['supply']
      }

      if(data['meta']['content'][0]['url']){
        metadata['visual'] = data['meta']['content'][0]['url']
      }

      console.log('metadata', metadata)
      callback(metadata)

    }

    else if(chain == 'matic'){
      console.log('matic')

      var spot = link.indexOf('collection/')
      var opensea_name = link.substring(spot+11,link.length)

      const response = await fetch('https://api.opensea.io/api/v1/collection/'+opensea_name)
      console.log('opensea response', response)    
      const data = await response.json()
      console.log('opensea response2', data)

      var metadata = {
        'contract': data['collection']['primary_asset_contracts'][0]['address'],
        'title': data['collection']['name'],
        'collectionLink': this.state.collectionLink,
        'type': 'other',
        'supply': data['collection']['stats']['count'],
        'chain': this.state.chain,
      }

      if(data['collection']['image_url']){
        metadata['visual'] = data['collection']['image_url']
      }

      console.log('metadata prepped', metadata)
      callback(metadata)

    }


  }

  submit(){
    var that = this
    console.log('hit submit')

    if(!this.props.collections){
      var existing = 0
    }
    else{
      var existing = this.props.collections.length    
    }

    console.log('existing', existing)

    this.getMetadata(function(metadata){
      console.log('ok', metadata)

      metadata['address'] = that.state.address
      metadata['spot'] = existing+1

      Axios.post('https://future-api-2.herokuapp.com/createCollection',metadata)
      .then(function (response) {
        console.log('response_back', response.data)
        if(response.data){
          that.setState({
            open: false           
          })
          that.props.refresh()          
        }

      })
      .catch(function (error) {
        console.log('This is a bad response',  error);    
        });    
    })

 
  }

  toggleOpen(){
    console.log('toggleOpen')

    if(this.state.open){
      this.setState({
        open: false,
        step: 1
      })
    }
    else {
      this.setState({
        open:true,
        step: 1
      })      
    }

  }

  chainSelect(e){
    console.log('hit chainSelect', e.target.value)

    this.setState({
      chain: e.target.value,
    })

  }

  continue(){
    console.log('continue', this.state.chain)
    this.setState({
      step: 2
    })
  }


  render(){

    var ready = true
    if(!this.state.artistName || !this.state.username){
      ready = false
    }

    var selectChain = <div className="card nft edit">
                  <div className="cardInfo">
                  <h4>Blockchain</h4>
                  <p>Please select the chain your NFT is minted on</p>
                  <select name="chains" id="chains" onChange={this.chainSelect}>
                    <option value="eth">Ethereum Mainnet</option>
                    <option value="matic">Polygon Matic</option>
                    <option value="tezos">Tezos</option>
                  </select>
                  </div>
                <div className="nftToggles conf">
                <span name="artistName" class="material-symbols-outlined" onClick={this.continue}>
                arrow_right_alt
                </span>
                <span name="artistName" class="material-symbols-outlined" onClick={this.toggleOpen}>
                close
                </span>
                </div>

              </div>



    var opensea = <div className="card nft edit">
                  <div className="cardInfo">
                  <h4>Opensea Link</h4>
                  <p>Please enter the link to your collection in Opensea</p>
                  <input className="input" name="collectionLink" value={this.state.collectionLink} onChange={this.handleChange} placeholder="opensea.io/collection/..."/>
                  </div>
                <div className="nftToggles conf">
                <span name="artistName" class="material-symbols-outlined" onClick={this.submit}>
                check
                </span>
                <span name="artistName" class="material-symbols-outlined" onClick={this.toggleOpen}>
                close
                </span>
                </div>

              </div>

    var rarible = <div className="card nft edit">
                  <div className="cardInfo">
                  <h4>Rarible Link</h4>
                  <p>Please enter the link to your collection in Rarible</p>
                  <input className="input" name="collectionLink" value={this.state.collectionLink} onChange={this.handleChange} placeholder="https://rarible.com/token/tezos/{COLLECTION_ID}:{TOKEN_ID}"/>
                  </div>
                <div className="nftToggles conf">
                <span name="artistName" class="material-symbols-outlined" onClick={this.submit}>
                check
                </span>
                <span name="artistName" class="material-symbols-outlined" onClick={this.toggleOpen}>
                close
                </span>
                </div>

              </div>

    var open = <></>

    if(this.state.step==1){
      open = selectChain;
    }
    else if(this.state.chain == 'eth' || this.state.chain == 'matic'){
      open = opensea
    }
    else if(this.state.chain == 'tezos'){
      open = rarible
    }

    var closed = <div className="card link new center" onClick={this.toggleOpen}>
            <span class="material-symbols-outlined">
            add
            </span>
          </div>

    var content = closed

    if(this.state.open){
      content = open
    }

    return content

  }
}

export default NewNFT
