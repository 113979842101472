import React, { Fragment } from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import { useParams, useRouteMatch, Switch, Route } from "react-router-dom";
import Axios from 'axios';
import {Link} from "react-router-dom";

import Model from "./Model";
import SubMenu from "./SubMenu";
import NFTs from './NFTs.jsx'
import Register from './Register.jsx'
import NewLink from './NewLink.jsx'

class Home extends React.Component {

  constructor(props){
      super(props);  

      this.state = {
      }
      this.lookupArtist = this.lookupArtist.bind(this)
      this.createArtist = this.createArtist.bind(this);
      this.toggleEditLink = this.toggleEditLink.bind(this)
      this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount(){
    var that = this;
    var address = this.props.address

    if(address && address !=that.state.address){
      that.setState({
        address: address
      })
      that.lookupArtist(address)
    }
 
  }

  lookupArtist = function(address){
    if(!address){
      var address=this.state.address
    }
    console.log('hit lookupArtist settings', address)
    var that = this;
    var drops = []

    Axios.post('https://future-api-2.herokuapp.com/lookupArtist',{wallet:address})
      .then(function (response) {
        console.log('response_back lookupArtist settings', response.data)
        var data = response.data
        if(!data){
          that.setState({
            'new': true,
            'oldData': {}
          })
        }
        else{
          that.setState({'oldData': data, 'loaded': true, 'new': false})
        }
      })
      .catch(function (error) {
        console.log('This is a bad response',  error);    
        });   

  }

  createArtist(e){
    var name=e.target.getAttribute('name')
    var name2 = "edit_"+name

    console.log('hit createArtist', name)
    console.log(this.state)

    var that = this;

    this.setState({
      attempted: true
    })

    var data = {account: this.state.address}
    if(that.state[name]){
        data[name] = that.state[name]
    }
    else {
        data[name] = ''
    }
    console.log('data', data)
  
    Axios.post('https://future-api-2.herokuapp.com/createArtist',data)
      .then(function (response) {
        console.log('response_back', response.data)
        var newState={
          created:true,
        }
        newState[name2] = false
        that.setState(newState)
        that.lookupArtist(that.state.address)
      })
      .catch(function (error) {
        console.log('This is a bad response',  error);    
        });   

  }

  toggleEditLink(e){

    var name=e.target.getAttribute('name')
    console.log('edit', name)
    name = 'edit_'+name

    var oldState = this.state[name]

    var newState = {}

    if(oldState){
      newState[name] = false
    }
    else{
      newState[name] = true
    }

    this.setState(newState)

  }
  

  handleChange(event){

    var name=event.target.name
    var newState = {}
    newState[name] = event.target.value.replace('@', '')
    console.log('state', newState)
    this.setState(newState)
  
  }





  render(){
    var that = this;
    var that = this;

    var icon = "https://i.ibb.co/6gp7LCH/vol-icon.png"
    var basic = <></>
    var links_content = <></>
    var errors = <></>

    if(!this.state.oldData){
      return (<></>)
    }
    else if(this.state.new){
      return <Register address={that.props.address} reload={this.lookupArtist}/>
    }

    var platforms = [
              {
                'name': 'twitter',
                'name2': 'Twitter'
              },
              {
                'name': 'instagram',
                'name2': 'Instagram'
              },
              {
                'name': 'spotify',
                'name2': 'Spotify'
              },
              {
                'name': 'apple',
                'name2': 'Apple'
              },
              {
                'name': 'youtube',
                'name2': 'YouTube'
              },
              {
                'name': 'soundcloud',
                'name2': 'SoundCloud'
              },
              {
                'name': 'discord',
                'name2': 'Discord'
              },
              {
                'name': 'telegram',
                'name2': 'Telegram'
              }

          ]

    basic = <div className="contentBottom">
          <h3>Basic Info</h3>
          <div className="wrapper">   

          {this.state.edit_artistName ? 
          <div className="card mainCard">
            <div className="cardSide">
            <h4>Artist Name</h4>
            <input 
                name="artistName" 
                placeholder={this.state.oldData.artistName ? this.state.oldData.artistName : 'i.e. "Jay-Z"'} 
                onChange={this.handleChange} 
                />
            </div>
            <div className="cardSide">
                <span name="artistName" className="material-symbols-outlined" onClick={this.createArtist}>
                check
                </span>
                <span name="artistName" className="material-symbols-outlined" onClick={this.toggleEditLink}>
                close
                </span>
                </div>
          </div>
          :
          <div className="card mainCard">
            <div className="cardSide">
            <h4>Artist Name</h4>
            <p>{this.state.oldData.artistName}</p>            
            </div>
            <div className="cardSide">
            <span name="artistName" onClick={this.toggleEditLink} className="material-symbols-outlined">
                settings
                </span>
                </div>
          </div>
          }

          {this.state.edit_username ? 
          <div className="card mainCard">
            <div className="cardSide">
            <h4>Username</h4>
            <input 
                name="username" 
                placeholder={this.state.oldData.username ? this.state.oldData.username : 'i.e. "Jay-Z"'} 
                onChange={this.handleChange} 
                />
            </div>
            <div className="cardSide">
                <span name="username" className="material-symbols-outlined" onClick={this.createArtist}>
                check
                </span>
                <span name="username" className="material-symbols-outlined" onClick={this.toggleEditLink}>
                close
                </span>
                </div>
          </div>
          :
          <div className="card mainCard">
            <div className="cardSide">
            <h4>Username</h4>
            <p>{this.state.oldData.username}</p>            
            </div>
            <div className="cardSide">
            <span name="username" onClick={this.toggleEditLink} className="material-symbols-outlined">
                settings
                </span>
                </div>
          </div>
          }
          </div>
          </div>

    links_content = platforms.map(function(item){
          var value = item['name']
          var title = item['name2']

          var edit_class = "edit_"+value

          return <> 
              {that.state.oldData[value] ?
              <div className={"card link "+value}>                             
                <h4>{title}</h4>

                {that.state[edit_class] ? <div className="linkSide">
                <input name={value} placeholder={that.state.oldData[value] ? that.state.oldData[value] : 'i.e. "joe123"' } onChange={that.handleChange}/>
                <span name={value} class="material-symbols-outlined" onClick={that.createArtist}>
                check
                </span>
                <span name={value} class="material-symbols-outlined" onClick={that.toggleEditLink}>
                close
                </span>
                </div>
                : <div className="linkSide">
                <p>{that.state.oldData[value].replace(/(^\w+:|^)\/\//, '').replace("www.", '').replace(/\/$/, "")}</p> 
                <span name={value} onClick={that.toggleEditLink} class="material-symbols-outlined">
                settings
                </span>
                <a  href={that.state.oldData[value]}>
                <span class="material-symbols-outlined">
                open_in_new
                </span>
                </a>
                </div>
                }

              </div>
              : <></>}
              </>

           })


    var links = <div className="contentBottom">
            <h3>Links</h3>
            <div className="wrapper">
            {links_content}
            <NewLink platforms={platforms} data={this.state.oldData} lookupArtist={this.lookupArtist} address={this.state.address}/>
           </div>
        </div>

    return (
      <div className="contentMain">
        <div className="contentTop">
        <a href={"https://www.vol.fm/"+that.state.oldData.username} className="button">
                {'vol.fm/'+that.state.oldData.username}
                <span class="material-symbols-outlined">
                open_in_new
                </span>
        </a>
         </div>
        {basic}
        {links}
        <NFTs address={that.props.address}/>
      </div>
    )

  }
}

export default Home
